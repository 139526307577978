<template>
  <main class="p-2 border-round cursor-pointer" :class="containerBgColor" @click.right.prevent="selectionHandler"
    @click="showModal">
    <!-- Modal -->
    <Dialog v-model:visible="modalDisplay" :modal="true">
      <!-- this put the close button to the right -->
      <template #header>
        <div style="width: 1px"></div>
      </template>

      <!-- content -->
      <div class="grid justify-content-around" id="infoContainer">
        <!-- image -->
        <div class="col-6">
          <img :src="photoUrl" />
        </div>
        <pv-Divider layout="vertical"></pv-Divider>
        <!-- info container -->
        <div class="col-4 flex flex-column">
          <div class="flex mb-4">
            <div class="flex flex-column mr-4">
              <Knob readonly v-model="CIR" :min="knobProps.min" :max="knobProps.max" :step="knobProps.step"
                value-color="rgb(237, 172, 107)" range-color="rgb(200, 200, 200)" />
              <p class="text-400 text-center">Auto</p>
            </div>
            <div class="flex flex-column">
              <Knob v-model="manual" :min="knobProps.min" :max="knobProps.max" :step="knobProps.step"
                value-color="rgb(237, 172, 107)" range-color="rgb(200, 200, 200)" />
              <p class="text-400 text-center">Manual</p>
            </div>
          </div>

          <!-- Time -->
          <div class="flex mb-4">
            <span class="material-icons-outlined align-self-center mr-3">today</span>
            <div class="align-self-center text-lg" style="white-space: pre-wrap; word-wrap: break-word">
              {{ new Date(props.data.info.time).toLocaleDateString('en-US', {
                  weekday: 'long', year:
                    'numeric', month: 'long', day: 'numeric'
                })
              }}
            </div>
          </div>
          <!-- Location -->
          <div class="flex mb-4">
            <span class="material-icons-outlined align-self-center mr-3">place</span>
            <div class="align-self-center text-lg" style="white-space: pre-wrap; word-wrap: break-word"
              :hidden="editLocationMode">
              {{ location }}
            </div>
            <AutoComplete v-model="newLocation" :suggestions="locationList" @complete="searchLocation($event)"
              :hidden="!editLocationMode" />

            <!-- white space, make edit icon to the right most -->
            <div class="flex-grow-1"></div>

            <Button icon="pi pi-pencil" class="p-button-rounded p-button-text align-self-center"
              @click="editLocationMode = !editLocationMode"></Button>
          </div>
          <!-- Room type -->
          <div class="flex mb-4">
            <span class="material-icons-outlined align-self-center mr-3">bed</span>
            <div class="align-self-center text-lg" style="white-space: pre-wrap; word-wrap: break-word"
              :hidden="editRoomTypeMode">
              {{ roomType }}
            </div>
            <AutoComplete v-model="newRoomType" :suggestions="roomTypeList" @complete="searchRoomType($event)"
              :hidden="!editRoomTypeMode" />
            <div class="flex-grow-1"></div>
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-text align-self-center"
              @click="editRoomTypeMode = !editRoomTypeMode"></Button>
          </div>
          <!-- Description -->
          <div class="flex mb-4">
            <span class="material-icons-outlined align-self-center mr-3">description</span>
            <div class="align-self-center text-lg" style="word-wrap: break-word" :hidden="editDescriptionMode">
              {{ description }}
            </div>
            <Textarea v-model="description" :autoResize="true" :hidden="!editDescriptionMode"></Textarea>
            <div class="flex-grow-1"></div>
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-text align-self-center"
              @click="editDescriptionMode = !editDescriptionMode"></Button>
          </div>

          <!-- white space -->
          <div class="flex-grow-1"></div>

          <!-- Buttons -->
          <Button icon="pi pi-cloud-upload" label="Update" class="w-full" :disabled="!changed"
            @click="updateInfo"></Button>
          <div class="flex flex-row w-full justify-content-between pt-3">
            <Button icon="pi pi-download" label="Download" class="mr-2 p-button-outlined w-full"
              @click="downloadImg"></Button>
            <Button icon="pi pi-trash" label="Delete" class="p-button-outlined p-button-danger ml-2 w-full"
              @click="confirmDeletion"></Button>
          </div>
        </div>
      </div>
    </Dialog>

    <!-- Card style -->
    <img :src="photoUrl" class="border-round" id="coverImg" />
    <div class="flex flex-row pt-1 px-2">
      <p class="text-xl font-bold m-auto" id="CIR">{{ props.data.info.CIR }}</p>
      <p class="flex-grow-1 m-auto">{{ storeImg.info.location }}</p>
    </div>
  </main>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { defineProps, inject, ref } from "vue";
import { useCookies } from 'vue3-cookies'

import Dialog from "primevue/dialog";
import Knob from "primevue/knob";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import AutoComplete from "primevue/autocomplete";
import Textarea from "primevue/textarea";

import { useImgListStore } from "@/views/DashboardPage.vue";
import { web_photo_update, web_photo_download, web_photo_batchDownload, web_photo_delete } from "@/assets/properties";

const props = defineProps(["data"]);
const axios = inject('axios')
const FormData = require('form-data');
const { cookies } = useCookies()

const imgListStore = useImgListStore();
const storeImg = imgListStore.getImgByImgId(props.data.info.imgId);

// variables
const CIR = ref(props.data.info.CIR);
const manual = ref(props.data.info.manual);
const location = ref(props.data.info.location);
const roomType = ref(props.data.info.roomType);
const description = ref(props.data.info.description);
const photoUrl = web_photo_download
  + "?userId=" + cookies.get("userId")
  + "&token=" + cookies.get("token")
  + "&photoId=" + props.data.info.imgId
const confirm = useConfirm();
const modalDisplay = ref(false);
// knobProps declared as variable because directly passing value in
// resulting in passing string rather than desired JS expression/statement
const knobProps = {
  step: 1,
  min: 0,
  max: 9,
  strokeWidth: 8,
  size: 50,
};

// edit info
const changed = computed(() => {
  return (
    CIR.value != props.data.info.CIR ||
    manual.value != props.data.info.manual ||
    location.value != props.data.info.location ||
    roomType.value != props.data.info.roomType ||
    description.value != props.data.info.description
  );
});

const editLocationMode = ref(false);
const newLocation = ref(location);
const locationList = ref(imgListStore.getLocationList);
const searchLocation = (event) => {
  let query = event.query;
  let filteredList = [];

  imgListStore.getLocationList.forEach((entry) => {
    if (entry["name"].toLowerCase().includes(query.toLowerCase())) {
      filteredList.push(entry["name"]);
    }
  });
  locationList.value = filteredList;
};

const editRoomTypeMode = ref(false);
const newRoomType = ref(roomType);
const roomTypeList = ref(imgListStore.getRoomTypeList);
const searchRoomType = (event) => {
  let query = event.query;
  let filteredList = [];

  imgListStore.getRoomTypeList.forEach((entry) => {
    if (entry["name"].toLowerCase().includes(query.toLowerCase())) {
      filteredList.push(entry["name"]);
    }
  });
  roomTypeList.value = filteredList;
};

const editDescriptionMode = ref(false);

// actions
const selectionHandler = () => {
  imgListStore.toggleSelection(props.data.info.imgId);
};
const showModal = () => {
  modalDisplay.value = !modalDisplay.value;
};
const downloadImg = () => {
  let config = {
    method: 'get',
    url: web_photo_batchDownload + "?userId=" + cookies.get("userId")
      + "&token=" + cookies.get("token") + "&photoIds=" + props.data.info.imgId,
    responseType: 'arraybuffer'
  };

  axios(config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
}
const confirmDeletion = () => {
  confirm.require({
    header: "Confirm Deletion",
    message: "Are you sure you want to delete?",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "p-button-danger",
    accept: () => {
      // delete photo in local store
      imgListStore.list.splice(imgListStore.getImgIndexByImgId(props.data.info.imgId), 1)

      // call API
      var data = new FormData();
      data.append('userId', cookies.get('userId'));
      data.append('token', cookies.get('token'));
      data.append('photoId', storeImg.info.imgId);
      let config = {
        method: 'delete',
        url: web_photo_delete,
        data: data
      }

      axios(config)
        .then((res) => {
          if (res.data.responseCode != 200) {
            console.error("Failed to delete " + storeImg.info.imgId)
          }
        })
        .catch(function (res) {
          console.error("Error when deleting " + storeImg.info.imgId)
          console.error(res)
        });
    },
    reject: () => {
    },
  });
};
const updateInfo = () => {
  // restore page to display mode
  editDescriptionMode.value = false;
  editLocationMode.value = false;
  editRoomTypeMode.value = false;

  // change local store
  let index = imgListStore.getImgIndexByImgId(props.data.info.imgId);
  let imgToMutate = imgListStore.list[index];
  imgToMutate.info.CIR = CIR.value;
  imgToMutate.info.manual = manual.value;
  imgToMutate.info.location = location.value;
  imgToMutate.info.roomType = roomType.value;
  imgToMutate.info.description = description.value;

  var data = new FormData();
  data.append('userId', cookies.get('userId'));
  data.append('token', cookies.get('token'));
  data.append('photoId', storeImg.info.imgId);
  data.append('manualRating', storeImg.info.manual);
  data.append('location', storeImg.info.location);
  data.append('description', storeImg.info.description);
  data.append('roomType', storeImg.info.roomType);

  var config = {
    method: 'post',
    url: web_photo_update,
    data: data
  };

  axios(config)
    .then(function () {
    })
    .catch(function (res) {
      console.error(props.data.info.imgId + ": update info failed")
      console.error(res)
    });
};

// automate css
const containerBgColor = computed(() => {
  return props.data.selected === true ? "selectedContainer" : "notSelectedContainer";
});
</script>

<style lang="scss" scoped>
main:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.col-6 img {
  // no overflow in 75vh
  height: 75vh;
  width: 100%;
  object-fit: contain;
}

#CIR {
  min-width: 20px;
  width: 20%;
}

#infoContainer {
  min-width: 1000px
}

#coverImg {
  height: 25vh;
}

p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.material-icons-outlined {
  color: rgb(110, 110, 110);
}

.notSelectedContainer {
  background-color: white;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 3%), 0px 0px 2px rgb(0 0 0 / 6%),
    0px 2px 6px rgb(0 0 0 / 12%);
}

.selectedContainer {
  background-color: var(--primary-color);
}

.p-dialog .p-dialog-header {
  padding: 0.5rem;
}
</style>
