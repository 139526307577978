import { createRouter, createWebHashHistory } from 'vue-router'
import Auth from '@/views/AuthPage.vue'
import Dashboard from '@/views/DashboardPage.vue'
import ForgotPasswordView from '@/views/ForgotPasswordPage.vue'
import Login from '@/components/LoginComponent.vue'
import SignUp from '@/components/SignUpComponent.vue'

const routes = [
    {
        path: '/',
        redirect: '/auth/login'
    },
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        redirect: '/auth/login',
        children: [
            {
                path: 'login',
                name: 'login',
                component: Login,
                meta: { transition: 'slide' },
            },
            {
                path: 'signup',
                name: 'signup',
                component: SignUp,
                meta: { transition: 'slide' },
            },
            { path: ':pathMatch(.*)*', redirect: '/auth/login' },
        ]
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { transition: 'slide' },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPasswordView,
        meta: { transition: 'slide' },
    },
    {
        path: '/:pathMatch(.*)*', // redirect uncatched to /auth
        redirect: '/auth'
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

// router.beforeEach((to, from, next) => {
//     console.log(`Navigating from ${from.path} to ${to.path}`); // Debugging
//     next();
//   });

export default router;
